import React from 'react';
import LegalLink from './LegalLink';
import AcceptTerms from './AcceptTerms';
import { Trans } from 'react-i18next';

export default function CommunityTerms() {
  return (
    <AcceptTerms>
      {/* prettier-ignore */}
      <Trans i18nKey="legal.boardmakerOnlineTerms">
        I agree to the
        <LegalLink  url="/TermsOfService.md">
          BMO Terms
        </LegalLink>
      </Trans>
    </AcceptTerms>
  );
}
