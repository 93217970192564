import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import axios from 'axios';
import querystring from 'querystring';

import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';

import FreeOrderSummary from './Invoice/FreeOrderSummary';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import BMOTerms from '../components/Legal/BMOTerms';
import URLS from '@config/urls';
import AlertError from '@util/Errors';
import CommunityTerms from './Legal/CommunityTerms';

export default function ConfirmBody({ plan }) {
  const { ssoUser } = useUser();
  const { selectedPlan } = usePlan();
  const formikRef = useRef();
  const [formError, setFormError] = useState(null);
  const { t } = useTranslation();

  function submitHandler() {
    const params = querystring.stringify({
      setSubDomainName: plan === 'trial',
    });

    axios({
      method: 'post',
      url: URLS.CREATE_BMO_ACCOUNT,
      data: params,
      headers: {
        Authorization: `Bearer ${ssoUser.access_token}`,
      },
    })
      .then(res => {
        //* if account was created successfully or already exists
        if (plan === 'trial') {
          var formData = new FormData();
          var trialType = 'classroom';
          if (selectedPlan.key === 'personal') {
            trialType = 'family';
          }
          formData.append('trialPlanType', trialType);

          axios({
            method: 'post',
            url: URLS.START_TRIAL,
            data: formData,
            headers: {
              Authorization: `Bearer ${ssoUser.access_token}`,
            },
          })
            .then(res => {
              //* if trial was created successfully
              if (res.status === 201) {
                completeYourProfile();
              }
            })
            .catch(err => {
              // TODO: handle error in the UI
              console.error(err.message);
              //* activate submit button to allow for resubmission
              formikRef.current.setSubmitting(false);
            });
        } else {
          completeYourProfile();
        }
      })
      .catch(err => {
        // TODO: handle error in the UI
        console.error(err.message);
        //* activate submit button to allow for resubmission
        formikRef.current.setSubmitting(false);
      });
  }

  function completeYourProfile() {
    const extension = '/Subscription/CompleteYourProfile';
    navigate('/redirect', {
      state: {
        extension,
      },
    });
  }

  return (
    <>
      <HeaderText className="row ml-0">
        {t('invoice.' + plan + 'Header')}&nbsp;
        <StartText>{t(plan + '.getStartedNow')}</StartText>
      </HeaderText>
      <AlertError
        isOpen={!!formError}
        dismiss={() => setFormError(false)}
        heading={t('validation.errorOccurred')}
      >
        {formError && formError.message}
      </AlertError>
      <br />
      <FreeOrderSummary plan={plan} />
      <Formik
        initialValues={{
          acceptTerms: false,
        }}
        ref={formikRef}
        validationSchema={Yup.object().shape({
          acceptTerms: Yup.bool().oneOf([true], t('validation.required')),
        })}
        onSubmit={submitHandler}
        render={({ isValid, isSubmitting }) => {
          return (
            <Form className="my-0">
              {plan === 'trial' && <BMOTerms />}
              {plan === 'community' && <CommunityTerms />}
              <br />
              <div>
                <button
                  type="submit"
                  id="CommunityOrTrialStart"
                  disabled={!isValid || isSubmitting}
                  className="btn btn-primary"
                >
                  {isSubmitting && (
                    <>
                      <span
                        className="spinner-corner spinner-corner-sm align-middle mt-n1"
                        role="status"
                        aria-hidden="true"
                      />{' '}
                    </>
                  )}
                  {isSubmitting ? t('status.submitting') : t('action.start')}
                </button>
                <button
                  type="button"
                  id="CommunityOrTrialCancel"
                  onClick={() => navigate('/')}
                  className="btn btn-outline-secondary"
                  disabled={isSubmitting}
                >
                  {t('action.cancel')}
                </button>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
}

const HeaderText = styled.span`
  display: inline;
`;

const StartText = styled.span`
  font-weight: bold;
`;
