import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { usePlan } from '@util/providers/Plan';

export default function FreeOrderSummary({ plan }) {
  const { selectedPlan, trialLength } = usePlan();
  const { t } = useTranslation();

  return (
    <>
      <br />
      <div>
        <hr className="m-0" />
      </div>

      <div className="d-none d-md-block font-weight-bold col-12">
        <div className="pt-4 pb-4 pr-4 pl-2">
          <div className="row">
            <div className="col-md-4">
              <div>{t('invoice.plan')}</div>
            </div>

            <div className="col-md-4">
              <div>{t('invoice.term')}</div>
            </div>
            <div className="col-md-4 text-right">
              <div>{t('invoice.price')}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-none d-md-block">
        <hr className="m-0" />
      </div>

      <div className="col-12">
        <div className="p-3 pt-md-4 pb-md-4 pr-md-4 pl-md-2">
          <div className="row">
            <div className="col-12 col-md-4">
              <div>
                <span className="font-weight-bold d-inline d-md-none text-capitalize">
                  {plan === 'community'
                    ? t('community.community')
                    : plan === 'trial'
                    ? t(selectedPlan?.key)
                    : null}
                </span>
                <span className="d-none d-md-inline text-capitalize">
                  {plan === 'community'
                    ? t('community.community')
                    : plan === 'trial'
                    ? t(selectedPlan?.key)
                    : null}
                </span>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div>
                {plan === 'community' ? (
                  t('Lifetime')
                ) : plan === 'trial' ? (
                  /* prettier-ignore */
                  <Trans i18nKey="trialLengthTrial">{{trialLength: trialLength}}-day trial </Trans>
                ) : null}
              </div>
            </div>
            <span className="col-12 col-md-4">
              <span className="d-none d-md-block text-right">{t('Free')}</span>
              <span className="d-md-none text-left">{t('Free')}</span>
            </span>
          </div>
        </div>
      </div>

      <div>
        <hr className="m-0" />
        <br />
      </div>
    </>
  );
}
