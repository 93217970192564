import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmBody from '../../components/ConfirmBody';

import ProtectedPage from '@components/ProtectedPage';
import Go from '@util/CustomRedirect';
import PageHeader from '@ui/PageHeader';
import SEO from '@components/seo';

import { useUser } from '@util/providers/AuthProvider';
import { usePlan } from '@util/providers/Plan';

export default function CommunityStart() {
  const { t } = useTranslation();

  return (
    <ProtectedPage>
      <Rules>
        <SEO title={t('page:joinTheCommunity')} />
        <PageHeader className="mb-4">{t('page:joinTheCommunity')}</PageHeader>
        <ConfirmBody plan={'community'} />
      </Rules>
    </ProtectedPage>
  );
}

function Rules({ children }) {
  const { bmoUser } = useUser();
  const { selectedPlan, selectedRate } = usePlan();
  const homeRules = bmoUser || !selectedPlan || !selectedRate;
  return (
    <Go to="/" when={homeRules}>
      {children}
    </Go>
  );
}
